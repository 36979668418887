import React from 'react'
import AddBible from '../API/addData/AddBible'
const Admin = () => {
    return (
        <div id='admin'>
            <div>Admin</div>
            {/* <div>
                <AddBible />
            </div> */}
        </div>
    )
}

export default Admin