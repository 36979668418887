import React from 'react'
import './css/Header.css'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const Header = ({ title, subTitle, isAdmin }) => {
    return (
        <div id='Header'>
            <a className='title' src="/">
                <h1>{title}</h1>
                <h5>{subTitle}</h5>
            </a>
            <div className='nav'>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    {isAdmin ? <li>
                        <Link to="/admin">Admin</Link>
                    </li> : null}
                    <li>
                        <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                        <Link to="/about">About Us</Link>
                    </li>
                </ul>
                {/* <Button variant="light">Home</Button>{' '}
                <Button variant="light">Blog</Button>{' '}
                <Button variant="light">About Us</Button>{' '}
                <Button variant="light">Login</Button>{' '} */}
            </div>
        </div>
    )
}

export default Header