import React from 'react'
import './css/BibleList.css'
import { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';

const BibleList = ({ data, gotoChapter }) => {
    const openBook = (val) => {
        const chapters = [];
        for (let i = 0; i < val.total_chapters; i++) {
            chapters.push(i + 1)
            // chapters.push(`<button onClick={gotoChapter(${i + 1})}>${i + 1}</button>`);
        }
        // console.log(chapters)
        return (<>
            <div className='chapterDropdown'>
                {chapters.map((value) => {
                    return <button onClick={() => gotoChapter(val.book_id, value, val)}>{value}</button>
                })}
            </div>
        </>)
    }
    return (
        <>
            <div className='list'>
                <Accordion flush>
                    <ListGroup
                        variant="flush"
                        as="ol"
                    >
                        {data.map((val, index) => {
                            // console.log(val.englishName);
                            return <>
                                <ListGroup.Item
                                    className='item'
                                    as="li" action
                                // onClick={() => setBookOpened(val)}
                                >
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header className='itemAcc'>
                                            {/* {val.book_name} */}
                                            {/* <BibleList data={val} /> */}
                                            <div className='bibleList'>
                                                <div className='bookName'>{val.book_name}</div>
                                                <div className='totalChapters'>{val.total_chapters} Chapters</div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {openBook(val)}
                                        </Accordion.Body>
                                    </Accordion.Item>


                                    {/* <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <BibleList data={val} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >Action</Dropdown.Item>
                                                <Dropdown.Item >Another action</Dropdown.Item>
                                                <Dropdown.Item >Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    {/* <BibleList data={val} /> */}
                                </ListGroup.Item>
                                {/* {bookOpened != null ? openBook(bookOpened) : null} */}

                                {/* <hr></hr> */}
                            </>
                        })}
                    </ListGroup>
                </Accordion>
            </div>
            {/* <label for={data.index}> */}



        </>
    )
}

export default BibleList