import { useState } from 'react';
import { Route, Routes } from 'react-router-dom'
import './App.css';
import Header from './Components/Header.jsx';
import Footer from './Components/Footer';
import Home from './Components/Home';
import About from './Components/About';
import Blog from './Components/Blog';
import Admin from './Admin/Admin';
import Missing from './Components/Missing'

function App() {
  const [isAdmin, setIsAdmin] = useState(false)
  return (
    <div className="App">
      <Header title={"Quran-Bible"} subTitle="A New way to Compare and Learn" isAdmin={isAdmin} />
      {/* <Home /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        {isAdmin ? <Route path="/admin" element={<Admin />} /> : null}
        <Route path="/blog" element={<Blog />} />
        <Route path='/about' element={<About />} />
        {/* <Route path= '/' /> */}
        <Route path='*' element={<Missing />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
