import axios from "axios";

const baseURL = "https://api.alquran.cloud/v1/search";

export const searchResult = async (data) => {
    let result = {}
    await axios.get(`${baseURL}/${data}/all/en.sahih`).then((response) => {
        result = response.data.data;
    });
    return result
}