// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import firebase from "firebase";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBm51QehQjNOJz6x4G2isWUDjD0J3pQWac",
    authDomain: "quran-bible-app.firebaseapp.com",
    projectId: "quran-bible-app",
    storageBucket: "quran-bible-app.appspot.com",
    messagingSenderId: "205886321033",
    appId: "1:205886321033:web:61c10b1a3c875b0e063158",
    measurementId: "G-4QY1CWGDCP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// export const db = firebase.firestore();