import React from 'react'
import './css/Footer.css';
const Footer = () => {
    return (
        <footer id='footer'>
            <p>2022 © CircuitBox Ltd</p>
        </footer>
    )
}

export default Footer