import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './css/SearchQuran.css'

export default function SearchQuran({ search, searchRes }) {
    console.log(searchRes)
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button variant="flash" onClick={handleShow}>
                {searchRes.count} results
            </button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={true} id='quranOffcanvas'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>"{search}" Search Results</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {searchRes.matches.map((val => {
                        // console.log(val)
                        return (<>
                            <a href='#' className='searchVerse'>
                                <span className='verseId'>{val.surah.number}:{val.numberInSurah}</span>
                                <span className='verse'>
                                    {val.text}
                                </span>
                            </a>
                            <hr></hr>

                        </>)

                    }))}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
