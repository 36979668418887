import React from 'react'
import './css/Bible.css'
import { useState, useEffect } from 'react';
import _ from 'lodash';
import data from '../Resouces//Bible/bible-books.json';
import ListGroup from 'react-bootstrap/ListGroup';
import BibleList from './bible/BibleList';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import Chapter from './bible/Chapter';
import BackLight from '../Images/backLight.png';
import searchLight from '../Images/searchLight.png'
import SearchBible from './bible/SearchBible';
import { searchResult } from '../API/Bible/search';
import searchSample from '../Resouces/Bible/seachSample.json';
import DropDownBible from './bible/DropDownBible'

const Bible = () => {
    let [selectedChap, setSelectedChap] = useState(null);
    const [search, setSearch] = useState(null)
    const [searchRes, setSearchRes] = useState({})
    const [searchOffset, setSearchOffset] = useState(0)

    const gotoChapter = (book_id, chptr, currentBook) => {
        // console.log(book_id, chptr)
        // setSelectedChap(`${book_id}_${chptr}`)
        setSelectedChap({
            bookData: currentBook,
            chapter: chptr
        })
    }

    const backHandler = () => {
        // console.log("backHandler")
        setSelectedChap(null)
    }

    const searchHandler = async (e, offset) => {
        e.preventDefault()
        setSearchRes(await searchResult(search, offset)) //CORS error is happening
        // setSearchRes(searchSample)
    }
    const prevBook = () => {
        const { bookData, chapter } = selectedChap
        if (chapter > 1) {
            setSelectedChap({
                bookData: selectedChap.bookData,
                chapter: chapter - 1
            })
        } else {
            let ind;
            let result = _.find(data, (n, index) => {
                if (n.book_id === selectedChap.bookData.book_id) {
                    ind = index
                    return true;
                }
            });

            if (ind !== 0) {
                setSelectedChap({
                    bookData: data[ind - 1],
                    chapter: data[ind - 1].total_chapters
                })
            } else {

                setSelectedChap({
                    bookData: data[data.length - 1],
                    chapter: data[data.length - 1].total_chapters
                })
            }

        }
    }

    const nextBook = () => {
        const { bookData, chapter } = selectedChap
        if (chapter < bookData.total_chapters) {
            setSelectedChap({
                bookData: selectedChap.bookData,
                chapter: chapter + 1
            })
        } else {
            let ind;
            let result = _.find(data, (n, index) => {
                if (n.book_id === selectedChap.bookData.book_id) {
                    ind = index
                    return true;
                }
            });
            if (ind !== data.length - 1) {
                setSelectedChap({
                    bookData: data[ind + 1],
                    chapter: 1
                })
            } else {

                setSelectedChap({
                    bookData: data[0],
                    chapter: 1
                })
            }
        }
    }

    // console.log(selectedChap)
    return (
        <div id='bible' className='book'>
            <div className='bookHeader'>
                <div >
                    <button onClick={() => backHandler()}><img src={BackLight}></img></button>
                    {/* <DropDownBible bookList={data} /> */}
                </div>
                <h2>Bible</h2>
                <div id='bibleSearch'>
                    <form className='searchForm' onSubmit={(e) => searchHandler(e, searchOffset)}>
                        {/* <label htmlFor='search'>Search Post</label> */}
                        <input
                            id="search"
                            type="text"
                            placeholder="Search Bible"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </form>
                    {/* <button onClick={(e) => searchhandler(e)}><img src={searchLight}></img></button> */}
                    <div id='searchResults'>
                        {Object.keys(searchRes).length !== 0 ?
                            <SearchBible search={search} searchRes={searchRes} searchOffset={searchOffset} setSearchOffset={setSearchOffset} searchHandler={searchHandler} setSelectedChap={setSelectedChap} />
                            :
                            null}
                    </div>
                    {/* <button><img src={searchLight}></img></button> */}
                </div>
                {/* <button onClick={() => searchHandler()}><img src={searchLight}></img></button> */}
            </div>
            <div className='bookContent'>
                {selectedChap == null ?
                    <BibleList data={data} gotoChapter={gotoChapter} />
                    :
                    <Chapter selectedChap={selectedChap} prevBook={prevBook} nextBook={nextBook} />
                }

            </div>
        </div>
    )
}

export default Bible