import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import prevDark from '../../Images/prevDarkSmall.png';
import nextDark from '../../Images/nextDarkSmall.png';
import './css/SearchBible.css'
import Chapter from './Chapter';

export default function SearchBible({ search, searchRes, searchOffset, setSearchOffset, searchHandler, setSelectedChap }) {
    // console.log(searchRes)
    const paginator = 10
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const linktoChapter = (ref) => {
        console.log(ref)
        let d = {
            "bookData": {
                "book_name": "1 Samuel",
                "book_id": "1Sam",
                "total_chapters": 31
            },
            "chapter": 1
        };
        setSelectedChap(d)
        // return <Chapter selectedChap={d} prevBook={null} nextBook={null} />
    }
    return (
        <>
            <button variant="flash" onClick={handleShow}>
                {searchRes.total} results
            </button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={true} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>"{search}" Search Results</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {searchRes.verses.map((val => {
                        // console.log(val)
                        return (<>
                            <button onClick={() => linktoChapter(val)} id={val.id} className='searchChapterVerse'>
                                <div className='searchVerseNumber'>{val.reference}</div>
                                <div className='searchVerse'>{val.text}
                                </div>
                            </button>
                            <hr></hr>

                        </>)

                    }))}
                    <div className='searchFooter'>
                        {searchOffset !== 0 ?
                            <button
                                onClick={(e) => {
                                    searchHandler(e, searchOffset - paginator);
                                    setSearchOffset(searchOffset - paginator)
                                }}><img src={prevDark} alt="prev"></img></button>
                            :
                            <button disabled ></button>
                        }
                        <div>
                            <p>Total search result: {searchRes.total}</p>
                            <p>Page number: {searchOffset / paginator + 1}</p>
                        </div>
                        {(searchRes.total > searchOffset + paginator)
                            ?
                            <button onClick={(e) => {
                                searchHandler(e, searchOffset + paginator);
                                setSearchOffset(searchOffset + paginator)
                            }}><img src={nextDark} alt="next" /></button>
                            :
                            <button disabled ></button>
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
