import React from 'react'
import { useEffect, useState } from 'react';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import './css/Chapter.css';
import utilDark from '../../Images/utilDark.png';
import nextDark from '../../Images/nextDarkSmall.png';
import prevDark from '../../Images/prevDarkSmall.png';
// import Loader from '../../util/Loader'

const Chapter = ({ selectedChap, prevBook, nextBook }) => {
    const [chapData, setChapData] = useState([])
    console.log(selectedChap)

    useEffect(() => {
        (async () => {
            const docRef = doc(db, "Bible", `${selectedChap.bookData.book_id}_${selectedChap.chapter}`);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                setChapData(docSnap.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })()
        // const unsub = onSnapshot(doc(db, "Bible", "Gen1"), (doc) => {
        //     console.log("Current data: ", doc.data());
        // });

    }, [selectedChap]);

    return (
        <>
            <div id='chapter'>
                {chapData.length !== 0 ?
                    <>
                        <div id='chapterHeader'>
                            <div>
                                <button onClick={() => prevBook()}><img src={prevDark}></img></button>
                            </div>
                            <h5>{selectedChap.bookData.book_name} {selectedChap.chapter}</h5>
                            <div>
                                <button onClick={() => nextBook()}><img src={nextDark}></img></button>
                                {/* <button><img src={utilDark}></img></button> */}
                            </div>
                        </div>
                        <div className='chapterContent'>
                            {Object.values(chapData).map((val => {
                                // console.log(val)
                                return (<>
                                    <div id={val.verse} className='chapterVerse'>
                                        <div className='verseNumber'>{val.verse}</div>
                                        <div className='verse'>
                                            {val.text}
                                        </div>
                                    </div>
                                    <hr></hr>

                                </>)

                            }))}
                        </div>
                    </>
                    :
                    null
                }
            </div>
        </>
    )
}

export default Chapter