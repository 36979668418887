import React from 'react'
import './css/QuranList.css'
import ListGroup from 'react-bootstrap/ListGroup';


const QuranList = ({ data, setSurah }) => {
    const openSurah = (data) => {
        // console.log(data)
        setSurah(data)
    }
    return (
        <div className='list'>
            <ListGroup
                variant="flush"
                as="ol"
            >
                {data.map(val => {
                    return <>
                        <ListGroup.Item
                            className='item'
                            as="li" action
                            onClick={() => openSurah(val)}>
                            <div className='quranList'>
                                <div className='surahNumber'>{val.surah}</div>
                                <div className='surahMain'>
                                    <div className='MainLine'>

                                        <div className='englishName'>{val.transliteration}</div>
                                        <div className='arabicName'>{val.name}</div>

                                    </div>
                                    <div className='secondLine'>
                                        <div className='totalVerses'>{val.total_verses} Verses</div>
                                        <div className='translation'>{val.translation}</div>
                                        <div>{val.revelation_type}</div>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>
                    </>
                })}
            </ListGroup>
        </div>

    )
}

export default QuranList