import React from 'react';
import { useState } from 'react';
import data from '../Resouces/Quran/quran-chapters.json';
import Surah from './quran/Surah';
import QuranList from './quran/QuranList';
import BackLight from '../Images/backLight.png';
import searchLight from '../Images/searchLight.png';
import './css/Quran.css'
import { searchResult } from '../API/Quran/search';
import SearchQuran from './quran/SearchQuran'

const Quran = () => {
    const [surah, setSurah] = useState(0);
    const [search, setSearch] = useState(null)
    const [searchRes, setSearchRes] = useState(null)
    // console.log(surah)
    const backHandler = () => {
        setSurah(0)
    }
    const prevSurah = () => {
        if (surah.surah === 1) {
            setSurah(data[data.length - 1])
        } else {
            setSurah(data[surah.surah - 2])
        }
    }
    const nextSurah = () => {
        if (surah.surah === data.length) {
            setSurah(data[0])
        } else {
            setSurah(data[surah.surah])
        }
    }
    const searchhandler = async (e) => {
        e.preventDefault()
        setSearchRes(await searchResult(search))
    }
    return (
        <div id='quran' className='book'>
            <div className='bookHeader'>
                <button onClick={() => backHandler()}><img src={BackLight} alt="back"></img></button>
                <h2>Quran</h2>
                <div id='quranSearch'>
                    <form className='searchForm' onSubmit={(e) => searchhandler(e)}>
                        {/* <label htmlFor='search'>Search Post</label> */}
                        <input
                            id="search"
                            type="text"
                            placeholder="Search Quran"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </form>
                    {/* <button onClick={(e) => searchhandler(e)}><img src={searchLight}></img></button> */}
                    <div id='searchResults'>
                        {searchRes ? <SearchQuran search={search} searchRes={searchRes} /> : null}
                    </div>
                    {/* <button><img src={searchLight}></img></button> */}
                </div>
            </div>
            <div className='bookContent'>
                {surah === 0 ?
                    <QuranList data={data} setSurah={setSurah} />
                    :
                    <Surah surah={surah} prevSurah={prevSurah} nextSurah={nextSurah} />
                }
            </div>
        </div>
    )
}

export default Quran