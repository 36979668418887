import React from 'react'
import Quran from './Quran';
import Bible from './Bible';
import './css/Home.css'
const Home = () => {
    // console.log(window.innerHeight, window.innerWidth)
    const onResizeHandler = (e) => {
        var percentage = (e.pageX / window.innerWidth) * 100;
        if (percentage > 25 && percentage < 75) {
            var mainPercentage = 100 - percentage;
            document.getElementById("quran").style.width = percentage + "%";
            document.getElementById("bible").style.width = mainPercentage + "%";
        }
    }
    return (
        <div id='Home'>
            <Quran />
            <button id='dragbar' draggable onDrag={(e) => onResizeHandler(e)}></button>
            <Bible />
        </div>
    )
}

export default Home