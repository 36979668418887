import axios from 'axios';

export const searchResult = async (text, offset) => {
    const baseUrl = `https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-01/search?query=${text}&offset=${offset}&sort=canonical`
    let result = {}
    var config = {
        headers: {
            'api-key': '2d98ef45a2064bba39adcbccd0c7a5ec'
        }
    };

    await axios.get(baseUrl, config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            result = response.data.data
        })
        .catch(function (error) {
            console.log(error);
        });
    return result
}