import React from 'react'
import './css/Surah.css';
import { playSurah } from '../../API/Quran/playSurah';

import Dropdown from 'react-bootstrap/Dropdown';
import utilDark from '../../Images/utilDark.png'
import nextDark from '../../Images/nextDarkSmall.png';
import prevDark from '../../Images/prevDarkSmall.png';
import noAudioDark from '../../Images/noAudioDark.png';
import audioDark from '../../Images/audioDark.png';
import { useState } from 'react';

const Surah = ({ surah, prevSurah, nextSurah, isPrevDisabled }) => {

    const [isAudio, setIsAudio] = useState(false)
    const playAudio = async (surah) => {
        console.log("play Audio")
        isAudio ? setIsAudio(false) : setIsAudio(true);
        // console.log(await playSurah(surah.surah))
    }
    return (
        <div id={`surah${surah.surah}`}>
            <div id='surahHeader'>
                <div>
                    <button onClick={() => prevSurah()} ><img src={prevDark}></img></button>
                    <h5>{surah.transliteration}</h5>
                </div>
                <h6>{surah.translation}</h6>
                <div>
                    {/* Add Audio files and GoTo verse functionality */}
                    {/* <button onClick={() => playAudio(surah)}>{isAudio ? <img src={noAudioDark} ></img> : <img src={audioDark} ></img>}</button> */}
                    <button onClick={() => nextSurah()}><img src={nextDark}></img></button>
                    {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={utilDark}></img>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    {/* <button><img src={utilDark}></img></button> */}
                </div>
            </div>
            <div className='surahContent'>
                {surah.ayahs.map((val => {
                    // console.log(val)
                    return (<>
                        <div id={val.id} className='surahVerse'>
                            <div className='verseNumber'>{val.id}</div>
                            <div className='verse'>
                                <div className='verseArabic'>{val.text}</div>
                                {/* <div>{() => { return val.transliteration }}</div> */}
                                <div className='verseTranslation'>{val.translation}</div>
                            </div>
                        </div>
                        <hr></hr>

                    </>)

                }))}
            </div>
        </div>
    )
}

export default Surah